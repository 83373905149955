@import url("./slider.css");
@import url("./input-labels.css");

.rsvp-section h2 {
    letter-spacing: 0.5rem;
}

/* Apply flexbox to the form container */
#rsvp-form {
    display: flex;
    flex-direction: column; /* Aligns children vertically */
    align-items: center; /* Center-aligns children horizontally */
    width: 100%; /* Use the full width of the form's container */
    max-width: 300px; /* Maximum width of the form */
    margin: auto; /* Center the form horizontally in the section */
}

#rsvp-form input,
#rsvp-form button,
.attendance-question /* Include the attendance question in the flex layout */
{ /* Ensure the switch is also included */
  margin-top: 0.5rem;
  padding: 0.5rem;
  /*width: 80%; /* Adjust if necessary for the switch */
  box-sizing: border-box; /* Ensures padding is included in the width */
}

#rsvp-form button {
  background-color: var(--dark-red);
  color: white;
  border: none;
  cursor: pointer;
}

#rsvp-form button:hover {
  background-color: var(--dark-red-hover);
}

/* Adjustments for specific elements if needed */
.attendance-question {
  font-size: 16px;
  width: 100%; /* Ensure full width to match input fields */
  text-align: center; /* Center-align the text */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch {
  justify-content: center; /* This centers the switch if it doesn't fill the width */
}

.feedback-message {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

.feedback-message.success {
  background-color: #4CAF50; /* Success color */
}

.feedback-message.error {
  background-color: #f44336; /* Error color */
}

/* Animation for fading out */
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.feedback-message.fade-out {
  animation-name: fadeOut;
  animation-duration: 2s; /* Adjust the duration as needed */
  animation-fill-mode: forwards; /* Keeps the element in the final state of the animation */
}
