/* accommodation.css */
.accommodation-section {
  text-align: left;
}

.accommodation-section ul {
  list-style: none;
  padding: 0;
}

.accommodation-section li {
  margin-bottom: 1rem;
}

.accommodation-section h3 {
  font-size: 1.5rem;
  color: var(--soft-green);
}

.accommodation-section p {
  font-size: 1rem;
  color: var(--dark-text);
}
