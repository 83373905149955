.form-group {
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group label {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #999;
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label {
  top: -10px;
  left: 0;
  font-size: 12px;
  color: #666;
}

/* Adjust the button style if necessary */
#rsvp-form button {
  margin-top: 0.5rem;
  padding: 0.5rem;
  width: 100%; /* Adjust based on your design */
  background-color: var(--dark-red);
  color: white;
  border: none;
  cursor: pointer;
}

#rsvp-form button:hover {
  background-color: var(--dark-red-hover);
}
