:root {
  --slider-width: 80px; /* Width of the slider */
  --slider-height: 34px; /* Height of the slider */
  --slider-background: #ccc; /* Slider background color */
  --slider-background-active: #2196F3; /* Slider active background color */
  --slider-circle-size: 26px; /* Diameter of the sliding circle */
  --slider-padding: 4px; /* Padding inside the slider for the circle to move */
  --text-color: white; /* Color of the text inside the slider */
  --font-size: 12px; /* Font size of the text inside the slider */
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: var(--slider-width);
  height: var(--slider-height);
}

/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--slider-background);
  transition: 0.4s;
  border-radius: var(--slider-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--slider-padding);
  color: var(--text-color);
  font-size: var(--font-size);
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--slider-circle-size);
  width: var(--slider-circle-size);
  left: var(--slider-padding);
  bottom: var(--slider-padding);
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--slider-background-active);
}

input:checked + .slider:before {
  transform: translateX(calc(var(--slider-width) - var(--slider-circle-size) - var(--slider-padding) * 2));
}

/* Yes and No text styles */
.slider .yes, .slider .no {
  color: var(--text-color);
  font-weight: bold;
  user-select: none;
  transition: opacity 0.4s ease-in-out; /* Smooth transition for opacity */
  opacity: 0; /* Default low opacity for both labels */
  padding: 5px;
}

.slider .no {
    color: var(--dark-text);
}

input:checked + .slider .yes {
  opacity: 1; /* Full opacity when "Yes" is selected */

}

input:not(:checked) + .slider .no {
  opacity: 1; /* Full opacity when "No" is selected */
}

/* Keeping the unselected label visible but faded */
input:checked + .slider .no,
input:not(:checked) + .slider .yes {
  opacity: 0; /* Lower opacity for unselected label */
}
