:root {
  --slider-width: 80px;
  --slider-height: 34px;
  --slider-background: #ccc;
  --slider-background-active: #2196f3;
  --slider-circle-size: 26px;
  --slider-padding: 4px;
  --text-color: white;
  --font-size: 12px;
}

.switch {
  width: var(--slider-width);
  height: var(--slider-height);
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: var(--slider-background);
  border-radius: var(--slider-height);
  padding: 0 var(--slider-padding);
  color: var(--text-color);
  font-size: var(--font-size);
  justify-content: space-between;
  align-items: center;
  transition: all .4s;
  display: flex;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  height: var(--slider-circle-size);
  width: var(--slider-circle-size);
  left: var(--slider-padding);
  bottom: var(--slider-padding);
  background-color: #fff;
  border-radius: 50%;
  transition: all .4s;
  position: absolute;
}

input:checked + .slider {
  background-color: var(--slider-background-active);
}

input:checked + .slider:before {
  transform: translateX(calc(var(--slider-width)  - var(--slider-circle-size)  - var(--slider-padding) * 2));
}

.slider .yes, .slider .no {
  color: var(--text-color);
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  padding: 5px;
  font-weight: bold;
  transition: opacity .4s ease-in-out;
}

.slider .no {
  color: var(--dark-text);
}

input:checked + .slider .yes, input:not(:checked) + .slider .no {
  opacity: 1;
}

input:checked + .slider .no, input:not(:checked) + .slider .yes {
  opacity: 0;
}

.form-group {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.form-group input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
}

.form-group label {
  color: #999;
  pointer-events: none;
  transition: all .3s;
  position: absolute;
  top: 10px;
  left: 10px;
}

.form-group input:focus + label, .form-group input:not(:placeholder-shown) + label {
  color: #666;
  font-size: 12px;
  top: -10px;
  left: 0;
}

#rsvp-form button {
  background-color: var(--dark-red);
  color: #fff;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: .5rem;
  padding: .5rem;
}

#rsvp-form button:hover {
  background-color: var(--dark-red-hover);
}

.rsvp-section h2 {
  letter-spacing: .5rem;
}

#rsvp-form {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: auto;
  display: flex;
}

#rsvp-form input, #rsvp-form button, .attendance-question {
  box-sizing: border-box;
  margin-top: .5rem;
  padding: .5rem;
}

#rsvp-form button {
  background-color: var(--dark-red);
  color: #fff;
  cursor: pointer;
  border: none;
}

#rsvp-form button:hover {
  background-color: var(--dark-red-hover);
}

.attendance-question {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  display: flex;
}

.switch {
  justify-content: center;
}

.feedback-message {
  text-align: center;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
}

.feedback-message.success {
  background-color: #4caf50;
}

.feedback-message.error {
  background-color: #f44336;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.feedback-message.fade-out {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.accommodation-section {
  text-align: left;
}

.accommodation-section ul {
  padding: 0;
  list-style: none;
}

.accommodation-section li {
  margin-bottom: 1rem;
}

.accommodation-section h3 {
  color: var(--soft-green);
  font-size: 1.5rem;
}

.accommodation-section p {
  color: var(--dark-text);
  font-size: 1rem;
}



/*# sourceMappingURL=content.41001d16.css.map */
